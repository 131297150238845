@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.error-content-widget {
  font-style: normal;
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0168em;
  color: #696969;
  margin-top: 80px;
}
