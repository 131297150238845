@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

$min-width: 375px;

html {
  height: 100%;
}

body {
  background-color: #fafdff;
  height: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: $min-width) {
  .loading-card {
    display: flexbox;
    justify-content: center;
    font-family: $font-family-base;
    align-items: center;
    box-sizing: border-box;
    width: 257px;
    height: 92px;
    background: #fff;
    border: 1px solid #c1c9cd;
    border-radius: 3px;
    position: inherit !important;
  }

  .error-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 472px;
    height: 168px;
    background: #fff;
    box-shadow: 0 3px 6px rgb(0 0 0 / 20%);
    border-radius: 3px;
  }

  .error-header {
    width: 424px;
    display: flex;
    flex-direction: row;
    padding: 24px 24px 0;
  }

  .error-header-icon {
    padding-bottom: 8px;
    padding-right: 9.67px;
    height: 30px !important;
    width: 30px !important;
    box-sizing: unset !important;
  }

  .error-header-text {
    width: 502px;
    height: 32px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 32px;
    color: #2b3337;
    order: 1;
    flex-grow: 0;
  }

  .error-content {
    width: 424px;
    height: 80px;
    padding: 0 24px 24px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2b3337;
    flex: none;
    order: 1;
    text-align: left;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 8px;
    word-spacing: -1px;
    box-sizing: unset !important;
  }

  .error-content-span {
    width: 424px;
    height: 80px;
    margin-top: 8px;
    font-family: $font-family-base;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2b3337;
  }
}
