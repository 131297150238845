@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.handover-card {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  overflow: hidden;
  width: 331px !important;
  height: 328px !important;
  margin-left: 1px;
  background: #fff;
  padding: 0% !important;
}

.handover-header {
  height: 51px;
  display: flex;
  padding-left: 16px;
  padding-top: 18px;
  padding-bottom: 16px;
}

.header-text {
  display: block;
  width: 240px;
  height: 22px;
  font-family: $font-family-base;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #2b3337;
}

.right-content {
  float: right;
  margin-left: 150px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  font-family: $font-family-base;
  margin-top: 5px;
  color: #71797d;
  letter-spacing: 1.44%;
}

.handover-refresh {
  color: #3b78bd !important;
  border: none !important;
  background-color: unset;
  height: 20px !important;
  width: 20px !important;
  padding-left: 1px !important;
}

.not-saved-warning {
  color: #ddaf4a !important;
  width: 18.33px !important;
  height: 15.33px !important;
}

.handover-item-container {
  margin-left: 16px;
  margin-right: 16px;
}

.text-area {
  width: 300px;
  height: 173px;
  margin-top: 8px !important;
  resize: none !important;
}

.text-area::-webkit-scrollbar {
  width: 0.5rem;
}

.text-area::-webkit-scrollbar-track {
  visibility: visible;
  background: #f1f1f1;
}

.text-area::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: none;
  border-radius: 20px;
}

.text-area::-webkit-scrollbar-track-piece:end {
  background: transparent !important;
}

.text-area::-webkit-scrollbar-track-piece:start {
  margin-top: 10px;
}

.texta-area::-webkit-scrollbar-thumb:vertical {
  margin: 0 2px; /* Add margin to the vertical scrollbar */
}

.action-buttons {
  padding-top: 16px !important;
  padding-bottom: 11px !important;
}

.save-button {
  min-width: 61px !important;
}

.save-button:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}

.clear-button {
  min-width: 61px !important;
}

.clear-button:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}

.last-updated {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.44%;
  font-family: $font-family-base;
  color: #696969;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;
  display: block;
  overflow: hidden;
}

.last-updated:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}
