@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.search-bar,
#txt-widget-search {
  padding-top: 7px !important;
  width: 250px !important;
  height: 32px;
  font-family: $font-family-base !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 19.91px !important;
  color: #8b9397 !important;
}

.search-bar-icon {
  color: #596165 !important;
  position: relative;
}

.search-invalid {
  border: 1px solid #e91d61 !important;
  box-shadow: none !important;
}

.widget-search-bar {
  width: 272px !important;
  font-family: $font-family-base !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  color: #8b9397 !important;
}

.search-no-results {
  padding-top: 15px !important;
  font-family: $font-family-base !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  font-style: normal;
}

.search-widget-item {
  padding-left: #{var(--spacing)} !important;
  overflow: auto !important;
}
