@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.feed-container {
  z-index: 9;
  max-width: 100% !important;
  max-height: 330px !important;
  margin: 16px !important;
}

#feed-card {
  box-sizing: border-box;
  border: 1px solid var(--neutral-70);
  border-radius: 3px;
  overflow: hidden;
  overflow-y: auto;
  height: 120px;
  max-height: 330px !important;
  min-height: 120px !important;
  margin-left: 1px;
  margin-bottom: 4px;
  background: var(--white);
  padding: 0 10px !important;
  resize: vertical;
}

.feed-header-container {
  height: 45px;
  display: flex;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 16px;
}

.feed-list-item {
  margin: 0;
}

.feed-header-text {
  display: block;
  width: 51px;
  height: 22px;
  font-family: $font-family-base;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: var(--black);
  white-space: nowrap;
}

.feed-item-container {
  display: block;
  white-space: unset;
  overflow: hidden;
}

#feed-card::-webkit-scrollbar {
  width: 0.5rem;
  margin-right: 5px !important;
}

#feed-card::-webkit-scrollbar-track {
  visibility: visible;
  background: var(--neutral-90);
  margin-right: 5px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

#feed-card::-webkit-scrollbar-thumb {
  background-color: var(--neutral-60);
  outline: none;
  border-radius: 20px;
}

#feed-card::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-70);
}

.feed-list-item-content {
  width: 500px;
  display: flex;
  justify-content: space-between;
  margin: 0% !important;
}

.feed-list-item-text {
  display: block;
  width: 500px;
  max-width: 500px;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.14%;
  color: var(--black);
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  cursor: default;
  text-align: left;
  text-decoration: none;
  padding: 0;
  border: none;
  background-color: transparent;
}

.empty-feed-item {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0168em;
  font-family: $font-family-base !important;
  color: var(--black) !important;
  text-align: center !important;
  width: 222px !important;
  height: 48px !important;
  margin: 10px auto;
}

.hyperlink-text {
  text-decoration: underline;
  cursor: pointer !important;
}

.blue {
  color: var(--accent-2-50);
}

.red {
  color: var(--urgent);
  font-weight: 700 !important;
  text-decoration-color: var(--urgent);
}

.green {
  color: var(--positive-40);
  font-weight: 700 !important;
  text-decoration-color: var(--positive-40);
}

.error-content-feed {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0168em;
  font-family: $font-family-base !important;
  color: var(--black) !important;
  text-align: center !important;
  width: 222px !important;
  height: 48px !important;
  margin: 10px auto;
}

.service-desk-info {
  display: block;
  width: 700px;
  max-width: 700px;
  height: 16px;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.14%;
  color: blue;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  cursor: default;
}

.version {
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.0144em;
  text-align: left;
  margin-left: 14px !important;
}

.feed-header-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
