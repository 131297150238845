#notification-container {
  line-height: 13px !important;
}

#error-notification {
  border: 1px solid var(--negative-inverse);
  display: flex;
  gap: var(--spacing-small);
  justify-content: space-between;
}

.error-notification-title {
  color: var(--negative) !important;
}

#success-notification {
  border: 1px solid var(--positive-inverse);
  width: 100%;
  display: flex;
  gap: var(--spacing-small);
  justify-content: space-between;
}

.success-notification-title {
  color: var(--positive) !important;
}

.notification-close-button {
  position: relative;
  float: right;
  margin-left: auto !important;
}
