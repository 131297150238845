@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.systemtray-notification-card {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  overflow: hidden;
  width: 331px !important;
  height: 328px !important;
  margin-left: 1px;
  background: #fff;
  padding: 0% !important;
}

.systemtray-notification-header-container {
  height: 51px;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 18px;
  padding-bottom: 16px;
}

.systemtray-notification-header-text {
  white-space: nowrap;
  display: block;
  font-family: $font-family-base;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #2b3337;
}

.systemtray-notification-item-container {
  width: 327px !important;
  height: 200px !important;
  display: block;
  white-space: unset;
  overflow: hidden;
  max-height: 258px;
  padding-left: 0% !important;
  overflow-y: auto;
}

.systemtray-notification-item-container::-webkit-scrollbar {
  width: 0.3rem !important;
}

.systemtray-notification-item-container::-webkit-scrollbar-track {
  visibility: hidden !important;
}

.systemtray-notification-item-container::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: none !important;
  border-radius: 20px !important;
}

.systemtray-notification-item-container::-webkit-scrollbar-track-piece:end {
  background: none !important;
}

.systemtray-notification-item-container::-webkit-scrollbar-track-piece:start {
  background: none !important;
  margin-top: 10px !important;
}

.empty-systemtray-notification-item {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.0168em;
  font-family: $font-family-base !important;
  color: #696969 !important;
  text-align: center !important;
  width: 222px !important;
  height: 48px !important;
  margin-left: 17% !important;
  margin-top: 25% !important;
}

.systemtray-notification-highlight {
  border: 1px solid #3a76ba !important;
  box-shadow: var(--primary-alpha-30, #3b78bd4d) 0 0 0 0.1875rem;
}

.systemtray-notification-action-buttons {
  height: 51px !important;
  display: flex !important;
  justify-content: space-between;
  padding-left: 16px !important;
  padding-top: 18px !important;
  padding-bottom: 16px !important;
}
