@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.reminder-card {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  overflow: hidden;
  width: 331px !important;
  height: 328px !important;
  margin-left: 1px;
  background: #fff;
  padding: 0% !important;
}

.reminder-header-text {
  display: block;
  width: 78px;
  height: 22px;
  font-style: normal;
  font-weight: 700;
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 22px;
  color: #333;
}

button.reminder-refresh-button {
  display: inline-flex;
  align-items: flex-start;
  background-color: unset;
  padding-left: 1px !important;
  border: none !important;
}

button.reminder-refresh-button svg {
  color: #3b78bd !important;
}

.reminder-tabs {
  font-style: normal !important;
  font-weight: 300 !important;
  font-family: $font-family-base !important;
  font-size: 16px !important;
  height: 38px !important;
  overflow-x: unset !important;
  overflow-y: unset !important;
  margin-left: 3% !important;
}

.reminder-tabs-border {
  border-top: 1px solid #dde5e9;
  padding-bottom: 9px;
  width: 91%;
  margin-left: 4.5%;
}

.reminder-tabs div {
  top: 28% !important;
}

.reminder-badge {
  top: 2% !important;
  border-radius: 12px !important;
}

.reminder-tabs div button {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px;
  font-family: $font-family-base !important;
  position: relative !important;
  margin-bottom: 16% !important;
  gap: 2px !important;
  padding: 0% 4%;
}

.reminder-tabs [data-state="active"] {
  font-family: $font-family-base !important;
  font-weight: 700 !important;
  background: transparent !important;
}

.reminder-tabs button:hover {
  background-color: transparent;
}

.tab-container {
  display: flex;
  justify-content: space-between;
  height: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.0144em;
  color: #333;
  margin-top: 4px !important;
  padding-left: 30px;
}

.reminder-list-item-line {
  border-top: 1px #d5d5d5;
  width: 93%;
  margin-left: 15px !important;
  margin-top: 0.2%;
  margin-bottom: 0%;
}

.reminder-item {
  display: block;
  width: 323px !important;
  white-space: unset;
  overflow: hidden;
  max-height: 178px;
  min-height: 176px;
  padding-left: 0% !important;
  overflow-y: auto;
}

.reminder-item::-webkit-scrollbar {
  width: 0.3rem;
}

.reminder-item::-webkit-scrollbar-track {
  visibility: hidden;
  padding-left: 0%;
}

.reminder-item::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: none;
  border-radius: 20px;
}

.reminder-item::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.reminder-item::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.list {
  width: 329px !important;
  height: 32px;
  padding: 8px 16px;
  transition: all 0.4s ease-out;
}

.list-item {
  width: 297px !important;
  height: 16px !important;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.0144em;
  color: #3b78bd;
  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  cursor: pointer !important;
  pointer-events: all !important;
  text-align: left;
  text-decoration: none;
  padding: 0;
  border: none;
  background-color: transparent;
}

.add-button-container {
  margin-left: 125px !important;
  display: flex;
  font-family: $font-family-base !important;
  z-index: 9;
  text-align: center;
}

#empty-reminders {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.0168em;
  font-family: $font-family-base;
  color: #696969;
  margin-left: 120px;
  margin-top: 90px;
}

.spinner {
  position: inherit !important;
  margin-top: 80px !important;
}

.retry-button {
  display: inline-block;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.fade.list-item.hide {
  transition: all 0.4s ease-out;
  opacity: 0;
}

.fade.list-item {
  opacity: 1;
}

.reminder-dismiss.clear-icon-enabled.fade.hide {
  transition: all 0.4s ease-out;
  opacity: 0;
}

.reminder-dismiss.clear-icon-enabled.fade {
  opacity: 1;
}

.reminder-dismiss.clear-icon-disabled {
  opacity: 0.6 !important;
  color: #3b78bd !important;
  pointer-events: none !important;
}

.reminder-dismiss.clear-icon-enabled {
  color: #3b78bd !important;
  pointer-events: all !important;
}

.reminder-dismiss.clear-icon-disabled:hover {
  cursor: not-allowed !important;
}

.reminder-dismiss.clear-icon-enabled:hover {
  cursor: pointer !important;
}

.refresh-icon {
  height: 20px !important;
  width: 20px !important;
}

#add-button:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}

.tab-today:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
  z-index: 10 !important;
}

.tab-tomorrow:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}

.tab-week:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}
