@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

$max-width: 700px;

.topbar-container {
  padding: 0 14px 0 17px !important;
  min-height: 165px !important;
  display: flex;
  flex-flow: column;
}

.topbar-first-row {
  display: flex;
  flex-flow: row wrap;
  min-height: 92px !important;
  justify-content: space-between;
}

.topbar-second-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.welcome {
  display: inline-block;
  margin-top: 35px;
  height: 35px;
  font-style: normal;
  font-weight: 700;
  font-family: $font-family-base;
  font-size: 29px;
  line-height: 35px;
  letter-spacing: -0.01em;
  color: #333;
}

.span-search-bar {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}

.span-search-bar div {
  width: auto;
}

.search-bar-or {
  font-style: normal;
  font-weight: 700;
  font-family: $font-family-base;
  padding: 1.5% 2% !important;
}

.manage-widgets-button {
  margin-top: 15px !important;
  margin-right: 16px !important;
}

.manage-widgets:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}

.whatsnew-button {
  margin-top: 15px !important;
  margin-right: 16px !important;
}

.whatsnew:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}

.support-centre-button {
  margin-top: 15px !important;
}

.supportcentre:focus-visible {
  outline: auto !important;
  outline-color: var(--black) !important;
}

.current-date {
  margin-top: 25px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: $font-family-base;
  color: #2b3337;
  letter-spacing: 1.44%;
}

.buttons {
  display: flex;
  width: 490px;
}

// (max-width: 700px)
@media screen and (max-width: $max-width) {
  .welcome {
    width: 100%;
  }

  .span-search-bar {
    flex-direction: column;
  }
}
