@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

#homepage-dialog {
  font-family: $font-family-base !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.0168em;
}

#dialog-content {
  width: 99% !important;
  max-height: 250px !important;
  overflow: auto !important;
}

#dialog-footer {
  display: block !important;
}

#dialog-content::-webkit-scrollbar {
  width: 0.5rem;
}

#dialog-content::-webkit-scrollbar-track {
  visibility: visible;
}

#dialog-content::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: none;
  border-radius: 20px;
}

#dialog-content::-webkit-scrollbar-track-piece:end {
  background: none;
}

#dialog-content::-webkit-scrollbar-track-piece:start {
  background: none;
  margin-top: 50px;
}
