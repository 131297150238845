@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.layout-container {
  background: #fbfdff;
  position: fixed;
  inset: 0%;
  max-height: 100vh;
  overflow-y: auto;
}

.layout-container::-webkit-scrollbar {
  width: 0.5rem;
  margin-right: 5px !important;
}

.layout-container::-webkit-scrollbar-track {
  visibility: visible;
  background: #e6e3e3;
  margin-right: 5px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.layout-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: none;
  border-radius: 20px;
}

.layout-container::-webkit-scrollbar-thumb:hover {
  background: #8a8989;
}
