@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.card-collection {
  display: flex;
  flex-flow: wrap row !important ;
  padding-bottom: 10px;
  min-height: 66vh;
}

.collection-container div {
  container-type: initial !important;
}

div.widget-loader {
  top: 40% !important;
  left: 40% !important;
  position: absolute !important;
}

.collection-container::-webkit-scrollbar {
  width: 0.5rem;
  margin-right: 5px !important;
}

.collection-container::-webkit-scrollbar-track {
  visibility: visible;
  margin-right: 5px !important;
}

.collection-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: none;
  border-radius: 20px;
}

.collection-container::-webkit-scrollbar-thumb:hover {
  background: #8a8989;
}

.collection-container::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.collection-container::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.collection-container {
  z-index: 9;
  margin: 0 16px 16px;
  max-width: 100% !important;
  max-height: 30% !important;
  overflow-y: auto;
}

@media screen and (width <=1366px) {
  .collection-container {
    z-index: 9;
    margin: 0 16px 16px;
    max-width: 100% !important;
    max-height: 55vh !important;
    overflow-y: auto;
    resize: inherit;
  }
}

// (min-width: 1367px) and (max-width: 1440px)
@media screen and (1367px <=width <= 1440px) {
  .collection-container {
    z-index: 9;
    margin: 0 16px 16px;
    max-width: 100% !important;
    max-height: 66vh !important;
    overflow-y: auto;
  }
}

// (min-width: 1441px) and (max-width: 1600px)
@media screen and (1441px <=width <= 1600px) {
  .collection-container {
    z-index: 9;
    margin: 0 16px 16px;
    max-width: 100% !important;
    max-height: 64vh !important;
    overflow-y: auto;
  }
}

// (min-width: 1601px) and (max-width: 1920px)
@media screen and (1601px <=width <= 1920px) {
  .collection-container {
    z-index: 9;
    margin: 0 16px 16px;
    max-width: 100% !important;
    max-height: 76vh !important;
    overflow-y: auto;
  }
}
