@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.btn {
  display: inline-block !important;
  border-radius: 100% !important;
  background-color: #e9f3ff !important;
  color: #3b78bd !important;
  width: 96px !important;
  height: 96px !important;
  padding: 27px !important;
}

.font-head {
  width: 200px !important;
  height: 32px !important;
  text-align: center !important;
  font-family: $font-family-base;
  margin-top: 16px !important;
}

.text-field {
  width: 391px !important;
  height: 20px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center !important;
  font-family: $font-family-base;
  margin-top: 10px !important;
}

.empty-widgets {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding-top: 10% !important;
  min-height: calc(100vh - 350px) !important;
}
