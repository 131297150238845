@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.whatsnew {
  font-family: $font-family-base;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.0168em;
  text-align: left;
}

.whats-new-icon {
  width: 20px !important;
  height: 20px !important;
}
