@import "~@emisgroup/ui-kit/build/dist/design-tokens/scss/design-tokens";

.widget-card {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-radius: 3px;
  overflow: hidden;
  width: 331px !important;
  height: 328px !important;
  margin-left: 1px;
  background: #fff;
  padding: 0% !important;
}

.widget-header-container {
  height: 51px;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-top: 18px;
  padding-bottom: 16px;
}

.widget-header-text {
  white-space: nowrap;
  display: block;
  font-family: $font-family-base;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #2b3337;
}

.widget-header-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px !important;
}

.widget-header-settings-icon {
  display: block;
  height: 20px !important;
  width: 20px !important;
  color: #3b78bd !important;
  cursor: pointer !important;
}

.widget-header-settings-button {
  background-color: unset !important;
  display: inline-flex !important;
  align-items: center !important;
  border: none !important;
}

.widget-header-refresh-icon {
  height: 20px !important;
  width: 20px !important;
}

button.widget-refresh-button {
  display: inline-flex !important;
  align-items: center !important;
  background-color: unset;
  margin-left: auto !important;
  padding: 0% !important;
  height: 20px !important;
  border: none !important;
}

button.widget-refresh-button svg {
  color: #3b78bd !important;
}

.widget-item-container {
  width: 327px !important;
  height: 256px !important;
  display: block;
  white-space: unset;
  overflow: hidden;
  max-height: 258px;
  padding-left: 0% !important;
  overflow-y: auto;
}

.widget-list {
  width: 327px !important;
  height: 32px;
  padding: 8px 16px;
  transition: all 0.4s ease-out;
}

.widget-list-item-content {
  width: 297px;
  height: 16px;
  display: flex;
  justify-content: space-between;
  margin: 0% !important;
}

.widget-list-item-line {
  border-top: 1px #d5d5d5;
  width: 93%;
  margin-left: 15px !important;
  margin-top: -0.3%;
  margin-bottom: 0%;
}

.widget-list-item-text {
  display: block;
  width: 258px;
  max-width: 225px;
  height: 16px;
  font-family: $font-family-base;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.14%;
  color: #3b78bd;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  cursor: pointer !important;
  pointer-events: all !important;
  text-align: left;
  text-decoration: none;
  padding: 0;
  border: none;
  background-color: transparent;
}

.widget-count-badge {
  display: block !important;
  width: auto !important;
  min-width: 23px !important;
  padding: 0% 8px !important;
  height: 16px !important;
  border-radius: 12px !important;
  background-color: #3b78bd;
}

.widget-count-value {
  display: inline-block !important;
  width: auto !important;
  min-width: 7px !important;
  padding: 0% !important;
  height: 16px !important;
  border-radius: 12px !important;
  line-height: 16px !important;
  font-family: $font-family-base !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  text-align: center !important;
  vertical-align: top !important;
  color: #fff !important;
  background-color: transparent !important;
}

.widget-zero-count {
  display: block !important;
  width: auto !important;
  min-width: 23px !important;
  padding: 0% 8px !important;
  height: 16px !important;
  border-radius: 12px !important;
  background-color: #8b9397 !important;
}

.widget-list-item-text.zero-count-item {
  display: block !important;
  width: 258px !important;
  max-width: 200px !important;
  height: 16px !important;
  font-family: $font-family-base !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 1.14% !important;
  color: #c1c9cd !important;
  overflow: hidden !important;
  overflow-wrap: break-word;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  pointer-events: none !important;
}

.widget-item-container::-webkit-scrollbar {
  width: 0.3rem !important;
}

.widget-item-container::-webkit-scrollbar-track {
  visibility: hidden !important;
}

.widget-item-container::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: none !important;
  border-radius: 20px !important;
}

.widget-item-container::-webkit-scrollbar-track-piece:end {
  background: none !important;
}

.widget-item-container::-webkit-scrollbar-track-piece:start {
  background: none !important;
  margin-top: 10px !important;
}

.empty-widget-item {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.0168em;
  font-family: $font-family-base !important;
  color: #696969 !important;
  text-align: center !important;
  width: 222px !important;
  height: 48px !important;
  margin-left: 17% !important;
  margin-top: 25% !important;
}

.widget-highlight {
  border: 1px solid #3a76ba !important;
  box-shadow: var(--primary-alpha-30, #3b78bd4d) 0 0 0 0.1875rem;
}

.last-refreshed-time {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  font-family: $font-family-base;
  margin-top: 2px;
  color: #71797d;
  letter-spacing: 1.44%;
  width: 34px;
}
